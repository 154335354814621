@tailwind base;
@tailwind components;
@tailwind utilities;

@media print {
  body {
    -webkit-print-color-adjust: exact;
    print-color-adjust: exact;
  }
}

@media screen and (max-width: 767px) {
  input,
  select,
  textarea {
    font-size: 16px !important;
  }
}

/* Work-around for vertical alignment issue with league-spartan font */
p {
  @apply -mb-1;
}

span {
  @apply -mb-1;
}

@media (min-width: 1024px) {
  p > img {
    width: 384px;
  }
}

@media (min-width: 1440px) {
  p > img {
    width: 440px;
  }
}

html {
  @apply font-sans text-[#262139] antialiased;
}

input[type='radio'] + label {
  @apply text-gray-600;
}

input[type='radio'] + label span {
  @apply mr-2 inline-block h-5 w-5 flex-shrink-0 rounded-full border border-[#302AFF];
  transition: 'background .2s, transform .2s';
}

input[type='radio'] + label span:hover,
input[type='radio'] + label:hover span {
  transform: scale(1.2);
}

input[type='radio']:checked + label span {
  @apply bg-[#302AFF];
  box-shadow: 0px 0px 0px 4px white inset;
}

input[type='radio']:checked + label {
  @apply border-[#2f3032];
}

/* Custom radio */
.custom-radio input[type='radio'] + label {
  @apply text-[#8E9197];
}

.custom-radio input[type='radio'] + label span {
  @apply mr-2 inline-block h-5 w-5 flex-shrink-0 rounded-full border border-[#D5D9E3];
  transition: 'background .2s, transform .2s';
}

.custom-radio input[type='radio'] + label span:hover,
.custom-radio input[type='radio'] + label:hover span {
  transform: scale(1.2);
}

.custom-radio input[type='radio']:checked + label span {
  @apply bg-zinc-800;
  box-shadow: 0px 0px 0px 5px white inset;
}

.custom-radio + input[type='radio']:checked + label {
  @apply border-[#2f3032];
}
